import React, { useEffect, useRef } from "react";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { AnimatePresence } from "framer-motion";
import { useUI } from "~/helpers/hooks/useUI";
import { subNavVariants } from "~/components/Header/constants";
import Icon from "~/components/Icon";
import { LocaleNavMenu, LocaleNavMenuItem, LocaleNavMenuWrapper } from "./styled";
import { localeNames } from "./constants";

const LocaleNav: React.FC = () => {
  const { localeNavOpen, closeLocaleNav } = useUI();
  const { language: currentLanguage, languages, originalPath } = useI18next();
  const { t } = useTranslation();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (localeNavOpen) {
      const activeLocaleLink = document.querySelector<HTMLDivElement>("#active-locale-link");
      if (menuRef.current && activeLocaleLink) {
        menuRef.current.scrollTop = activeLocaleLink.offsetTop;
      }
    }
  }, [localeNavOpen]);

  return (
    <AnimatePresence>
      {localeNavOpen && (
        <LocaleNavMenuWrapper
          ref={menuRef}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={subNavVariants}
          transition={{ ease: "easeInOut" }}
        >
          <LocaleNavMenu aria-label={t("a11y_locale_menu")}>
            {languages.map((language) => {
              const isActive = language === currentLanguage;
              return (
                <LocaleNavMenuItem
                  as={Link}
                  key={`locale-nav-item--${language}`}
                  to={originalPath}
                  language={language}
                  onClick={closeLocaleNav}
                  id={isActive ? "active-locale-link" : undefined}
                  $isActive={isActive}
                >
                  {localeNames[language]}
                  {isActive && <Icon type="check" />}
                </LocaleNavMenuItem>
              );
            })}
          </LocaleNavMenu>
        </LocaleNavMenuWrapper>
      )}
    </AnimatePresence>
  );
};

export default LocaleNav;
