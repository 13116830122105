import { ResponsiveValue, TLengthStyledSystem as Value } from "styled-system";

type Transformer<T> = (prop: ResponsiveValue<T>) => ResponsiveValue<T>;

export const trimUnit = (s: string): string => s.replace(/([0-9]|\.|,)+([\S]+)?/, "$2").trim();

export const transformProp = <T extends Value>(transform: (value: T) => T): Transformer<T> => (prop) => {
  if (prop == null) return null;

  if (Array.isArray(prop)) {
    return prop.map((value) => (value != null ? transform(value) : null));
  }

  if (typeof prop === "object") {
    const tranformedObject = Object.fromEntries(
      Object.entries(prop).map(([key, value]) => {
        return [key, value != null ? transform(value) : null];
      }),
    );

    return tranformedObject as ResponsiveValue<T>;
  }

  return transform(prop);
};

export const calcSpan = (columns: number): Transformer<Value> =>
  transformProp((value) => {
    const int = typeof value === "string" ? parseInt(value, 10) : value;
    return `${(int / columns) * 100}%`;
  });

export const calcGutter = (isRow: boolean): Transformer<Value> =>
  transformProp((value) => {
    const n = isRow ? -2 : 2;

    if (typeof value === "string") {
      return parseFloat(value) / n + trimUnit(value);
    }

    return value / n;
  });

export const calcRowGutter = calcGutter(true);
export const calcColGutter = calcGutter(false);
export const flexBasis = transformProp((value) => `0 0 ${value}`);
