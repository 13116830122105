import { useEffect, useState } from "react";

export function useMatchMedia(mediaQuery: string): boolean | null {
  const [matches, setMatches] = useState<boolean | null>(null);

  useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setMatches(event.matches);
    }

    const mediaQueryList = window.matchMedia(mediaQuery);

    setMatches(mediaQueryList.matches);
    mediaQueryList.addEventListener("change", onChange);
    return () => mediaQueryList.removeEventListener("change", onChange);
  }, [mediaQuery]);

  return matches;
}
