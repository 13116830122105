/** Generates an array with key aliases, for use with styled-system responsive props. */
export function responsiveAlias<A extends Array<string | number>, R = Record<string, string | number>>(
  propertiesObject: R,
): A & R {
  const aliasedArray = ([] as unknown) as A & R;

  for (const [key, value] of Object.entries(propertiesObject)) {
    aliasedArray.push(value);
    aliasedArray[<keyof R>key] = value;
  }

  return aliasedArray;
}
