import React from "react";
import Helmet from "react-helmet";
import type { SiteMeta } from "~/types/meta";
import type { BrandMeta } from "~/types/brand";
import type { ChapterMeta } from "~/types/chapter";

interface SeoProps {
  data: {
    favicon: string;
    site: SiteMeta;
    brand?: BrandMeta;
    page?: ChapterMeta;
  };
  language?: string;
  dir?: string;
}

const SEO: React.FC<SeoProps> = ({ data, language = "en-us", dir = "" }) => {
  const { site, brand, page, favicon } = data;

  const siteTitle = site.site_title;
  const siteDescription = site.site_description;
  const siteShareImage = site.share_image.url;

  const brandTitle = brand?.title;
  const brandDescription = brand?.description;
  const brandShareImage = brand?.share_image?.url;

  const pageTitle = page?.page_title;
  const pageDescription = page?.page_description;
  const pageShareImage = page?.share_image?.url;

  const titleString = pageTitle && brandTitle ? `${pageTitle} – ${brandTitle} | ${siteTitle}` : `${siteTitle}`;
  const description = siteDescription || brandDescription || pageDescription;
  const shareImage = pageShareImage || brandShareImage || siteShareImage;

  return (
    <Helmet
      htmlAttributes={{ lang: language, dir }}
      defaultTitle={siteTitle}
      title={titleString}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          property: "og:title",
          content: titleString,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:image",
          content: shareImage,
        },
        {
          property: "og:type",
          content: "webiste",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: titleString,
        },
        {
          name: "twitter:description",
          content: description,
        },
      ]}
      link={[
        {
          rel: "icon",
          href: favicon,
          type: "image/png",
        },
      ]}
    />
  );
};

export default SEO;
