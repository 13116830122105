import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { AnimatePresence } from "framer-motion";
import { useTheme } from "styled-components";
import BrandNav from "~/components/BrandNav";
import LocaleNavButton from "~/components/LocaleNav/LocaleNavButton";
import LocaleNavMenu from "~/components/LocaleNav";
import { ResponsiveImage } from "~/components/Image";
import { Flex } from "~/components/Layout";
import { Text } from "~/components/Text";
import Icon from "~/components/Icon";
import { useChapterContext } from "~/helpers/hooks/useChapterContext";
import { getChapterUrl } from "~/helpers/getChapterUrl";
import { useMatchMedia } from "~/helpers/hooks/useMatchMedia";
import { useUI } from "~/helpers/hooks/useUI";
import { HeaderProps } from "./types";
import { subNavMotionConfig } from "./constants";
import {
  BrandLogo,
  BrandLogoLink,
  HeaderContainer,
  MainNav,
  MainNavInner,
  SubNav,
  DownloadsLink,
  LogoLink,
} from "./styled";

const SCROLL_DELTA_THRESHOLD = 5;

const Header: React.FC<HeaderProps> = ({ header_logo }) => {
  const lastScrollTopRef = useRef(0);
  const [headerCollapsed, setHeaderCollapsed] = useState(false);
  const { brandNavOpen, pageTransitionActive } = useUI();
  const theme = useTheme();
  const isDesktop = useMatchMedia(theme.mediaQueries.md);
  const isMobile = !useMatchMedia(theme.mediaQueries.sm);
  const { brand, chapter } = useChapterContext();
  const { t } = useTranslation();

  const [firstChapter] = brand?.chapters || [];

  // useMemo dependencies are purposefully non-exhaustive to avoid image flashing
  /* eslint-disable react-hooks/exhaustive-deps */
  const responsiveLogo = useMemo(
    () => <img src={isMobile ? header_logo[0]?.url : (header_logo[1] || header_logo[0])?.url} />,
    [isMobile],
  );
  const responsiveBrandLogo = useMemo(() => !!brand?.logo && <ResponsiveImage loading="eager" image={brand?.logo} />, [
    brand?.meta?.title,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const handleScroll = () => {
      // only enable nav hub hiding on brand pages
      if (!brand) return;

      const scrollTop = document?.scrollingElement?.scrollTop || 0;
      if (Math.abs(lastScrollTopRef.current - scrollTop) > SCROLL_DELTA_THRESHOLD) {
        setHeaderCollapsed(scrollTop > lastScrollTopRef.current && scrollTop >= window.innerHeight / 2);
        lastScrollTopRef.current = scrollTop;
      }
    };

    if (!brandNavOpen) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [brand, brandNavOpen]);

  useEffect(() => {
    if (pageTransitionActive) setHeaderCollapsed(false);
  }, [pageTransitionActive]);

  return (
    <HeaderContainer $collapsed={headerCollapsed} $hasBrandNav={!!brand}>
      <MainNav aria-label={t("a11y_main_nav")}>
        <MainNavInner>
          <Flex height="100%" alignItems="center">
            <LogoLink to="/">{responsiveLogo}</LogoLink>
            {!!brand && (
              <BrandLogoLink to={getChapterUrl(brand?.slug, firstChapter?.slug)}>
                <BrandLogo>{responsiveBrandLogo}</BrandLogo>
                {!!(isDesktop && brand.meta?.title) && (
                  <Text typeStyle="nav-link" pl="1.125rem">
                    {brand.meta?.title}
                  </Text>
                )}
              </BrandLogoLink>
            )}
          </Flex>
          {!!brand && (
            <DownloadsLink to={getChapterUrl(brand?.slug, "downloads")}>
              <Text as="span" typeStyle="nav-link" mr="sm" textColor="primary">
                {t("downloads")}
              </Text>
              <Icon type="arrow-up-right" height="0.5rem" />
            </DownloadsLink>
          )}
          <LocaleNavButton />
        </MainNavInner>
      </MainNav>
      <LocaleNavMenu />
      <AnimatePresence>
        {!!brand?.chapters && !!chapter && (
          <SubNav {...subNavMotionConfig}>
            <BrandNav headerCollapsed={headerCollapsed} />
          </SubNav>
        )}
      </AnimatePresence>
    </HeaderContainer>
  );
};

export default Header;
