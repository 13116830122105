import { useContext } from "react";
import { ChapterContext, ChapterContextProviderProps } from "~/context/chapter";

export const useChapterContext = (): Partial<ChapterContextProviderProps> => {
  const context = useContext(ChapterContext);

  if (context === undefined) {
    throw new Error("useChapterContext must be used within a ChapterContextProvider");
  }

  return context;
};
