import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "~/components/Text";
import { getChapterUrl } from "~/helpers/getChapterUrl";
import { DesktopBrandNavWrapper, BrandNavItem } from "./styled";
import { ChapterContextProviderProps } from "~/context/chapter";
import { useChapterContext } from "~/helpers/hooks/useChapterContext";

const DesktopBrandNav: React.FC = () => {
  const { t } = useTranslation();
  const { brand, currentChapterIndex } = useChapterContext() as ChapterContextProviderProps;
  const { slug: brandSlug, chapters } = brand || {};

  if (!brandSlug || !chapters) return null;

  return (
    <DesktopBrandNavWrapper aria-label={t("a11y_chapter_nav")}>
      {chapters.map((chapter, chapterIndex) => (
        <BrandNavItem
          to={getChapterUrl(brandSlug, chapter.slug)}
          key={`brand-nav-item--${chapter.slug}`}
          $numChapters={chapters.length}
          $navItemState={Math.sign(chapterIndex - currentChapterIndex)}
        >
          <Text as="span" typeStyle="nav-link">
            {chapter.meta.page_title}
          </Text>
        </BrandNavItem>
      ))}
    </DesktopBrandNavWrapper>
  );
};

export default memo(DesktopBrandNav);
