import { pxToRem } from "~/helpers/pxToRelativeUnit";
import type { ThemeDefinition, ThemeColors, ThemeTypeStyles, ThemeSpace, ThemeOptions } from "./types";

const name = "default";

const options: ThemeOptions = {
  uppercaseHeadings: false,
};

const space: ThemeSpace = {
  none: pxToRem(0),
  "2xs": pxToRem(6),
  xs: pxToRem(8),
  sm: pxToRem(12),
  base: pxToRem(16),
  lg: pxToRem(20),
  xl: pxToRem(24),
  "2xl": pxToRem(28),
  "3xl": pxToRem(32),
  "4xl": pxToRem(40),
  "5xl": pxToRem(60),
  "6xl": pxToRem(70),
  "7xl": pxToRem(80),
  "8xl": pxToRem(120),
  "9xl": pxToRem(140),
  "10xl": pxToRem(240),
  "11xl": pxToRem(300),
};

const brandColors = {
  red: "#D13639",
  black: "#000000",
  white: "#FFFFFF",
};

const colors: ThemeColors = {
  background: brandColors.white,
  foreground: brandColors.black,
  primary: brandColors.black,
  secondary: brandColors.red,
  accent: brandColors.red,
  body: brandColors.black,
  utility: {
    primary: brandColors.black,
    secondary: "#2b2a29",
  },
  nav: {
    background: brandColors.black,
    foreground: brandColors.white,
    border: brandColors.black,
    primary: brandColors.black,
    secondary: "#2b2a29",
    subnav: brandColors.black,
  },
  border: {
    primary: "#2b2a29",
    secondary: "#2b2a29",
  },
  button: {
    primary: {
      text: brandColors.white,
      border: brandColors.red,
      background: brandColors.black,
    },
    secondary: {
      text: "#2b2a29",
      border: "#2b2a29",
      background: "#00a0ba",
    },
    link: {
      text: "#2b2a29",
      border: "#2b2a29",
      background: "#e7e6e3",
    },
  },
};

const fontStyleSheets = ["/fonts/inter.css"];

const fonts = {
  heading: "Inter",
  body: "Inter",
};

const typeStyles: ThemeTypeStyles = {
  "heading-xs": {
    fontFamily: "heading",
    fontSize: pxToRem(12),
    lineHeight: 1,
    letterSpacing: "0.04em",
    fontWeight: 700,
  },
  "heading-sm": {
    fontFamily: "heading",
    fontSize: pxToRem(18),
    lineHeight: 1.12,
    letterSpacing: "0.025em",
    fontWeight: 700,
  },
  "heading-base": {
    fontFamily: "heading",
    fontSize: pxToRem(24),
    lineHeight: 1.12,
    letterSpacing: "0.02em",
    fontWeight: 700,
  },
  "heading-lg": {
    fontFamily: "heading",
    fontSize: pxToRem(28),
    lineHeight: 1.08,
    letterSpacing: "0.01em",
    fontWeight: 700,
  },
  "heading-xl": {
    fontFamily: "heading",
    fontSize: pxToRem(32),
    lineHeight: 1.08,
    letterSpacing: "0.01em",
    fontWeight: 700,
  },
  "heading-2xl": {
    fontFamily: "heading",
    fontSize: pxToRem(40),
    lineHeight: 1.08,
    letterSpacing: "0em",
    fontWeight: 700,
  },
  "heading-3xl": {
    fontFamily: "heading",
    fontSize: pxToRem(48),
    lineHeight: 1.08,
    letterSpacing: "0em",
    fontWeight: 700,
  },
  "heading-4xl": {
    fontFamily: "heading",
    fontSize: pxToRem(60),
    lineHeight: 1.08,
    letterSpacing: "0em",
    fontWeight: 700,
  },
  "heading-5xl": {
    fontFamily: "heading",
    fontSize: pxToRem(90),
    lineHeight: 1.08,
    letterSpacing: "-0.01em",
    fontWeight: 700,
  },
  "body-xs": {
    fontFamily: "body",
    fontSize: pxToRem(10),
    lineHeight: 1,
    letterSpacing: "0.04em",
  },
  "body-sm": {
    fontFamily: "body",
    fontSize: pxToRem(14),
    lineHeight: 1.26,
    letterSpacing: "0.02em",
  },
  "body-base": {
    fontFamily: "body",
    fontSize: pxToRem(16),
    lineHeight: 1.24,
    letterSpacing: "0.01em",
  },
  button: {
    fontFamily: "body",
    fontSize: pxToRem(12),
    lineHeight: 1.26,
    letterSpacing: "0.1em",
  },
  "nav-link": {
    fontFamily: "heading",
    fontSize: pxToRem(12),
    fontWeight: 700,
    lineHeight: 1.26,
    letterSpacing: "0.06em",
  },
};

const decorators = {
  rule: {
    small: "",
    large: "",
  },
  label: {
    left: "",
    right: "",
  },
  bullet: "",
  nextChapter: "",
};

const cursors = {
  default: "default",
  pointer: "pointer",
  drag: "ew-resize",
};

const localeOverrides = {};

const theme: ThemeDefinition = {
  name,
  options,
  fontStyleSheets,
  fonts,
  colors,
  space,
  typeStyles,
  decorators,
  cursors,
  localeOverrides,
};

export default theme;
