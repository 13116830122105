import { createGlobalStyle } from "styled-components";

/**
 * Global styles let you style outer tags like html or body.
 * As a general rule, try to keep global styles to a minimum since these
 * can silently affect any other styles.
 * https://styled-components.com/docs/api#createglobalstyle
 */
export const GlobalStyles = createGlobalStyle`
  :root {
    --ease-bezier: cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  html {
    color: ${(props) => props.theme.colors.foreground};
    background-color: ${(props) => props.theme.colors.background};
  }

  body {
    overscroll-behavior-block: none;
    text-rendering: optimizeLegibility;
    cursor: ${({ theme }) => theme.cursors.default};
  }

  ::selection {
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.background};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  p > a {
    text-decoration: underline;
  }

  /**
  * Removes the default spacing and border for appropriate elements.
  */
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  button {
    background-color: transparent;
    background-image: none;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }

  /* Apply cursor:pointer to all buttons and a tags */
  a, button,
  [role="button"] {
    cursor: ${({ theme }) => theme.cursors.pointer};
  }

  @keyframes fade-up {
    from {
      transform: translateY(1rem);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .animate-inview > * {
    animation: fade-up 1150ms var(--ease-bezier) forwards;
  }

  @keyframes scale-up {
    from {
      transform: translateY(1rem) scale(0.9);
      opacity: 0;
    }
    to {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }

  .animate-inview .animate-inview-scale {
    animation: scale-up 1150ms var(--ease-bezier) forwards;
  }

`;
