import { useRef, useEffect, useCallback, RefObject } from "react";
import { useEventListener } from "~/helpers/hooks/useEventListener";

interface UseScrollEventListenerCallbackArgs {
  scrollTop: number;
  scrollProgress: number;
}

export type UseScrollEventListenerCallback = ((args: UseScrollEventListenerCallbackArgs) => void) | (() => void);

/**
 * `useScrollEventListener` is used to create optimally performant scroll-event
 *  callback effects.
 *
 * This hook does not call the callback method on every scroll event, instead, it uses
 * `window.requestAnimationFrame` to call the callback at an interval so as to avoid
 * having the callback method block the main render thead.
 *
 * The callback method provided to this hook should expect an object with two numerical values:
 * - scrollTop: the # of pixels the user has scrolled within the window/target element
 * - scrollProgress: a percentage value (0->1) representing the users scroll position relative to the window/target element
 *
 * If you are expecting the default event listener behavior, please use the `useEventListener` hook.
 *
 * @param callback the method to be called at an interval while the user is scrolling
 * @param enabled boolean to toggle the scrolling event listener
 * @param ref optional ref object to target when listening for scroll events
 */
export function useScrollEventListener(
  callback: UseScrollEventListenerCallback,
  enabled = true,
  ref?: RefObject<HTMLElement>,
): void {
  const callbackRef = useRef<UseScrollEventListenerCallback | null>(null);
  const frameRef = useRef<number | null>(null);
  const prevScrollTop = useRef<number>(0);

  const stopAnimationFrame = useCallback(() => {
    if (frameRef.current) window.cancelAnimationFrame(frameRef.current);
    frameRef.current = null;
  }, []);

  const update = useCallback(() => {
    if (document.scrollingElement) {
      let scrollProgress, scrollTop;

      // passing in a ref will make the scrollTop / scrollHeight relative to that ref, otherwise defaulting to the document
      if (ref && ref.current) {
        const { top, height } = ref.current.getBoundingClientRect();
        const relativeTop = top + document.scrollingElement.scrollTop;
        const viewportScroll = Math.min(relativeTop, window.innerHeight);
        const totalScroll = viewportScroll + height;
        scrollTop = -top + viewportScroll;
        scrollProgress = scrollTop / totalScroll;
      } else {
        scrollTop = document.scrollingElement.scrollTop;
        const scrollHeight = document.scrollingElement.scrollHeight;
        scrollProgress = scrollTop / (scrollHeight - window.innerHeight);
      }

      callbackRef.current?.({ scrollTop, scrollProgress });
      if (frameRef.current && Math.abs(scrollTop - prevScrollTop.current) > 0.1) {
        prevScrollTop.current = scrollTop;
        frameRef.current = window.requestAnimationFrame(update);
        return;
      }
    }
    stopAnimationFrame();
  }, [stopAnimationFrame, ref]);

  const startAnimationFrame = useCallback(() => {
    if (frameRef.current === null) {
      frameRef.current = window.requestAnimationFrame(update);
    }
  }, [update]);

  useEffect(() => {
    callbackRef.current = callback;
    return stopAnimationFrame;
  }, [callback, stopAnimationFrame]);

  useEventListener(enabled ? "scroll" : null, startAnimationFrame, { initial: true });
}
