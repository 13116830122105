import { getCountry } from "../helpers/country";
import { CountryConfig } from "../page-wrapper";

interface CountrySetting {
  gdpr?: boolean;
  preferredLocales?: Array<string>;
  skipGeoCookieCheck?: boolean;
}

// A default mapping of each country to a language (preferredLocales) and locale (RiotLocale) or to specify their age or GDPR policy.

export const countryConfig: { [key: string]: CountrySetting } = {
  AU: { preferredLocales: ["en-au"] },
  NZ: { preferredLocales: ["en-au"] },
  GU: { preferredLocales: ["en-us"] },
  MP: { preferredLocales: ["en-us"] },
  FJ: { preferredLocales: ["en-gb"] },
  MH: { preferredLocales: ["en-us"] },
  VU: { preferredLocales: ["en-au"] },
  CK: { preferredLocales: ["en-gb"] },
  PG: { preferredLocales: ["en-au"] },
  AS: { preferredLocales: ["en-us"] },
  SB: { preferredLocales: ["en-gb"] },
  TO: { preferredLocales: ["en-au"] },
  BR: { preferredLocales: ["pt-br"] },
  RE: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  GP: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  MQ: { preferredLocales: ["fr-fr"], gdpr: true },
  GF: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  FR: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  MC: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  DE: { preferredLocales: ["de-de", "en-gb"], gdpr: true },
  AT: { preferredLocales: ["de-de", "en-gb"], gdpr: true },
  CH: { preferredLocales: ["de-de", "en-gb"], gdpr: true },
  LU: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  LI: { preferredLocales: ["de-de", "en-gb"], gdpr: true },
  FO: { preferredLocales: ["en-gb"], gdpr: true },
  CW: { preferredLocales: ["en-gb"], gdpr: true },
  SX: { preferredLocales: ["en-us"], gdpr: true },
  VC: { preferredLocales: ["en-us"], gdpr: true },
  VG: { preferredLocales: ["en-us"], gdpr: true },
  KN: { preferredLocales: ["en-us"], gdpr: true },
  HT: { preferredLocales: ["en-us"], gdpr: true },
  NC: { preferredLocales: ["en-au"], gdpr: true },
  PF: { preferredLocales: ["en-us"], gdpr: true },
  MN: { preferredLocales: ["en-gb"], gdpr: true },
  PM: { preferredLocales: ["en-us"], gdpr: true },
  MM: { preferredLocales: ["en-gb"], gdpr: true },
  PL: { preferredLocales: ["pl-pl", "en-pl", "en-gb"], gdpr: true },
  ES: { preferredLocales: ["es-es", "en-gb"], gdpr: true },
  SE: { preferredLocales: ["en-gb"], gdpr: true },
  IT: { preferredLocales: ["it-it", "en-gb"], gdpr: true },
  NL: { preferredLocales: ["en-gb"], gdpr: true },
  DK: { preferredLocales: ["de-de", "en-gb"], gdpr: true },
  NO: { preferredLocales: ["en-gb"], gdpr: true },
  RO: { preferredLocales: ["ro-ro", "en-gb"], gdpr: true },
  BE: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  GR: { preferredLocales: ["el-gr", "en-gb"], gdpr: true },
  PT: { preferredLocales: ["pt-br", "en-gb"], gdpr: true },
  HU: { preferredLocales: ["hu-hu", "en-gb"], gdpr: true },
  FI: { preferredLocales: ["en-gb"], gdpr: true },
  CZ: { preferredLocales: ["cs-cz", "en-gb"], gdpr: true },
  BG: { preferredLocales: ["en-gb"], gdpr: true },
  RS: { preferredLocales: ["ro-ro", "en-gb"], gdpr: true },
  HR: { preferredLocales: ["de-de", "en-gb"], gdpr: true },
  LT: { preferredLocales: ["en-gb"], gdpr: true },
  IE: { preferredLocales: ["en-gb"], gdpr: true },
  ZA: { preferredLocales: ["en-gb"], gdpr: true },
  SI: { preferredLocales: ["en-gb"], gdpr: true },
  EE: { preferredLocales: ["en-gb"], gdpr: true },
  LV: { preferredLocales: ["en-gb"], gdpr: true },
  SK: { preferredLocales: ["hu-hu", "en-gb"], gdpr: true },
  BA: { preferredLocales: ["en-gb"], gdpr: true },
  IS: { preferredLocales: ["en-gb"], gdpr: true },
  MK: { preferredLocales: ["en-gb"], gdpr: true },
  ME: { preferredLocales: ["en-gb"], gdpr: true },
  AL: { preferredLocales: ["el-gr", "en-gb"], gdpr: true },
  AD: { preferredLocales: ["es-es", "en-gb"], gdpr: true },
  GL: { preferredLocales: ["en-gb"], gdpr: true },
  NA: { preferredLocales: ["en-gb"], gdpr: true },
  MV: { preferredLocales: ["en-gb"], gdpr: true },
  KE: { preferredLocales: ["en-gb"], gdpr: true },
  SR: { preferredLocales: ["en-us"], gdpr: true },
  GY: { preferredLocales: ["en-us"], gdpr: true },
  AG: { preferredLocales: ["en-us"], gdpr: true },
  NG: { preferredLocales: ["en-gb"], gdpr: true },
  SN: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  YT: { preferredLocales: ["en-gb"], gdpr: true },
  MG: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  LC: { preferredLocales: ["en-us"], gdpr: true },
  GD: { preferredLocales: ["en-us"], gdpr: true },
  GH: { preferredLocales: ["en-gb"], gdpr: true },
  CI: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  AO: { preferredLocales: ["es-es", "en-gb"], gdpr: true },
  MZ: { preferredLocales: ["en-gb"], gdpr: true },
  MF: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  SM: { preferredLocales: ["it-it", "en-gb"], gdpr: true },
  ZW: { preferredLocales: ["en-gb"], gdpr: true },
  BQ: { preferredLocales: ["en-us"], gdpr: true },
  BW: { preferredLocales: ["en-gb"], gdpr: true },
  ZM: { preferredLocales: ["en-gb"], gdpr: true },
  CM: { preferredLocales: ["en-gb"], gdpr: true },
  DJ: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  TZ: { preferredLocales: ["en-gb"], gdpr: true },
  UG: { preferredLocales: ["en-gb"], gdpr: true },
  SD: { preferredLocales: ["en-gb"], gdpr: true },
  GA: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  ET: { preferredLocales: ["en-gb"], gdpr: true },
  SC: { preferredLocales: ["en-gb"], gdpr: true },
  MR: { preferredLocales: ["en-gb"], gdpr: true },
  TC: { preferredLocales: ["en-us"], gdpr: true },
  CG: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  GN: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  CV: { preferredLocales: ["en-gb"], gdpr: true },
  BJ: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  ML: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  AI: { preferredLocales: ["en-us"], gdpr: true },
  TG: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  NE: { preferredLocales: ["fr-fr", "en-gb"], gdpr: true },
  BF: { preferredLocales: ["en-gb"], gdpr: true },
  SZ: { preferredLocales: ["en-gb"], gdpr: true },
  LR: { preferredLocales: ["en-gb"], gdpr: true },
  LS: { preferredLocales: ["en-gb"], gdpr: true },
  GQ: { preferredLocales: ["es-es", "en-gb"], gdpr: true },
  RW: { preferredLocales: ["en-gb"], gdpr: true },
  SO: { preferredLocales: ["en-gb"], gdpr: true },
  SL: { preferredLocales: ["en-gb"], gdpr: true },
  MW: { preferredLocales: ["en-gb"], gdpr: true },
  GB: { preferredLocales: ["en-gb"], gdpr: true },
  MT: { preferredLocales: ["en-gb"], gdpr: true },
  JE: { preferredLocales: ["en-gb"], gdpr: true },
  GG: { preferredLocales: ["en-gb"], gdpr: true },
  IM: { preferredLocales: ["en-gb"], gdpr: true },
  GI: { preferredLocales: ["en-gb"], gdpr: true },
  HK: { preferredLocales: ["en-gb"], gdpr: true },
  JP: { preferredLocales: ["ja-jp"] },
  KR: { preferredLocales: ["ko-kr"] },
  MX: { preferredLocales: ["es-mx"] },
  CL: { preferredLocales: ["es-mx"] },
  AR: { preferredLocales: ["es-mx"] },
  CO: { preferredLocales: ["es-mx"] },
  CR: { preferredLocales: ["es-mx"] },
  PE: { preferredLocales: ["es-mx"] },
  UY: { preferredLocales: ["es-mx"] },
  EC: { preferredLocales: ["es-mx"] },
  DO: { preferredLocales: ["es-mx"] },
  PA: { preferredLocales: ["es-mx"] },
  PR: { preferredLocales: ["es-mx", "en-us"] },
  VE: { preferredLocales: ["es-mx"] },
  BO: { preferredLocales: ["es-mx"] },
  SV: { preferredLocales: ["es-mx"] },
  GT: { preferredLocales: ["es-mx"] },
  PY: { preferredLocales: ["es-mx"] },
  HN: { preferredLocales: ["es-mx"] },
  NI: { preferredLocales: ["es-mx"] },
  DM: { preferredLocales: ["es-mx"] },
  CU: { preferredLocales: ["es-mx"] },
  BD: { preferredLocales: ["en-gb"] },
  NP: { preferredLocales: ["en-gb"] },
  IN: { preferredLocales: ["en-gb"] },
  LK: { preferredLocales: ["en-gb"] },
  MU: { preferredLocales: ["en-gb"] },
  PK: { preferredLocales: ["en-gb"] },
  BT: { preferredLocales: ["en-gb"] },
  KH: { preferredLocales: ["fr-fr", "en-gb"] },
  TL: { preferredLocales: ["en-us"] },
  LA: { preferredLocales: ["en-gb"] },
  CN: { preferredLocales: ["zh-tw"] },
  US: { preferredLocales: ["en-us"] },
  CA: { preferredLocales: ["en-us"] },
  TT: { preferredLocales: ["en-us"] },
  JM: { preferredLocales: ["en-us"] },
  BS: { preferredLocales: ["en-us"] },
  BB: { preferredLocales: ["en-us"] },
  AW: { preferredLocales: ["en-us"] },
  KY: { preferredLocales: ["en-us"] },
  BM: { preferredLocales: ["en-us"] },
  VI: { preferredLocales: ["en-us"] },
  BZ: { preferredLocales: ["en-us"] },
  VN: { preferredLocales: ["vi-vn", "en-sg"] },
  TW: { preferredLocales: ["zh-tw"] },
  PH: { preferredLocales: ["en-ph", "en-sg", "en-gb"] },
  SG: { preferredLocales: ["en-sg", "en-gb"] },
  TH: { preferredLocales: ["th-th", "en-sg"] },
  ID: { preferredLocales: ["id-id", "en-sg"] },
  MY: { preferredLocales: ["ms-my"] },
  AE: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  MA: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  SA: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  IL: { preferredLocales: ["ar-ae", "en-gb"] },
  KW: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  EG: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  QA: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  LB: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  BN: { preferredLocales: ["ar-ae", "en-gb", "en-gb"], gdpr: false },
  JO: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  TN: { preferredLocales: ["ar-ae", "fr-fr", "en-gb"], gdpr: false },
  OM: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  BH: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  DZ: { preferredLocales: ["ar-ae", "fr-fr", "en-gb"], gdpr: false },
  IQ: { preferredLocales: ["ar-ae", "en-gb"], gdpr: false },
  PS: { preferredLocales: ["en-gb"] },
  AF: { preferredLocales: ["en-gb"] },
  SY: { preferredLocales: ["en-gb"] },
  LY: { preferredLocales: ["en-gb"] },
  YE: { preferredLocales: ["en-gb"] },
  IR: { preferredLocales: ["en-gb"] },
  MO: { preferredLocales: ["en-gb"] },
  RU: { preferredLocales: ["ru-ru"] },
  UA: { preferredLocales: ["ru-ru"] },
  BY: { preferredLocales: ["ru-ru"] },
  GE: { preferredLocales: ["en-gb"] },
  KZ: { preferredLocales: ["ru-ru"] },
  MD: { preferredLocales: ["ru-ru"] },
  AM: { preferredLocales: ["ru-ru"] },
  AZ: { preferredLocales: ["tr-tr"] },
  KG: { preferredLocales: ["ru-ru"] },
  UZ: { preferredLocales: ["ru-ru"] },
  TJ: { preferredLocales: ["ru-ru"] },
  TM: { preferredLocales: ["ru-ru"] },
  TR: { preferredLocales: ["tr-tr"], gdpr: true },
  CY: { preferredLocales: ["en-gb"], gdpr: true, skipGeoCookieCheck: true },
  XK: {},
};

export const getCountryProperties = (countryMapping: CountryConfig): CountrySetting => {
  const properties = countryMapping ? countryMapping[getCountry()] : countryConfig[getCountry()];
  return {
    // defaults
    ...{
      gdpr: false,
    },
    ...properties,
  };
};
