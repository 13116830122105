import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { compose, layout, space } from "styled-system";
import type { BoxProps } from "~/components/Layout/types";
import css, { Theme as StyledSystemTheme } from "@styled-system/css";
import { ThemeTypeStyles } from "~/styles/themes/types";

type RichTextElement = "p" | "h1" | "h2" | "h3" | "h4" | "h5";

type RichTextTypeStyles = {
  [key in RichTextElement]?: keyof ThemeTypeStyles;
};

type StyledRichTextTypeStyles = Required<RichTextTypeStyles>;

interface RichTextProps extends BoxProps {
  children?: string;
  typeStyles?: RichTextTypeStyles;
}

interface RichTextStyle {
  theme: DefaultTheme;
  $typeStyles: StyledRichTextTypeStyles;
}

const richTextSystem = compose(layout, space);

const richTextStyle = (element: RichTextElement) => ({ theme, $typeStyles }: RichTextStyle) => {
  return css((theme) => theme.typeStyles[$typeStyles[element]])(theme as StyledSystemTheme);
};

const StyledRichText = styled.div<{ $typeStyles: StyledRichTextTypeStyles }>`
  overflow: hidden;

  > * + *,
  li + li {
    margin-top: ${({ theme }) => theme.space.base};
  }

  h1 {
    ${richTextStyle("h1")}
  }

  h2 {
    ${richTextStyle("h2")}
  }

  h3 {
    ${richTextStyle("h3")}
  }

  h4 {
    ${richTextStyle("h4")}
  }

  h5 {
    ${richTextStyle("h5")}
  }

  p,
  li {
    ${richTextStyle("p")}
    color: ${({ theme }) => theme.colors.body};
  }

  ul {
    padding-left: ${({ theme }) => theme.space.base};
    list-style-type: circle;
    list-style-image: ${({ theme }) => `url("${theme.decorators.bullet}")`};
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.secondary};
  }

  ${richTextSystem}
`;

const defaultTypeStyles: StyledRichTextTypeStyles = {
  h1: "heading-4xl",
  h2: "heading-3xl",
  h3: "heading-2xl",
  h4: "heading-xl",
  h5: "heading-lg",
  p: "body-base",
};

const RichText: React.FC<RichTextProps> = ({ children, typeStyles = {}, ...rest }) => {
  if (!children) return null;

  const _typeStyles = { ...defaultTypeStyles, ...typeStyles };

  return <StyledRichText $typeStyles={_typeStyles} dangerouslySetInnerHTML={{ __html: children }} {...rest} />;
};

export default RichText;
