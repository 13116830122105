import React from "react";

interface IconProps {
  type: string;
  height?: string | number;
}

/**
 * The `Icon` component uses a sprite sheet to house icons that are used across themes.
 * The sprite sheet is located at `/static/icon.svg`.
 * @param type string referring to a key in the sprite sheet.
 * @param height height is used to set the size of the icon.
 *     The width of the icon will scale with the height acording to the icon's aspect ratio.
 */
const Icon: React.FC<IconProps> = ({ type, height = 12, ...rest }) => {
  return (
    <svg height={height} viewBox="0 0 12 12" {...rest}>
      <use xlinkHref={`/icon.svg#${type}`} />
    </svg>
  );
};

export default Icon;
