import React, { useEffect } from "react";
import { createContext, useCallback, useReducer } from "react";
import { useBodyLock } from "~/helpers/hooks/useBodyLock";

interface UIState {
  brandNavOpen: boolean;
  localeNavOpen: boolean;
  pageTransitionActive: boolean;
}

export interface UIContextProps extends UIState {
  resetUI: () => void;

  openBrandNav: () => void;
  closeBrandNav: () => void;
  toggleBrandNav: () => void;

  openLocaleNav: () => void;
  closeLocaleNav: () => void;
  toggleLocaleNav: () => void;

  notifyPageTransitionStarted: () => void;
  notifyPageTransitionEnded: () => void;
}

enum UIAction {
  resetUI = "RESET_UI",
  setBrandNav = "SET_BRAND_NAV",
  setLocaleNav = "SET_LOCALE_NAV",
  setPageTransition = "SET_PAGE_TRANSITION",
}

const initialState: UIState = {
  brandNavOpen: false,
  localeNavOpen: false,
  pageTransitionActive: false,
};

function reducer(state: UIState, action: { type: UIAction; payload?: boolean }): UIState {
  switch (action.type) {
    case UIAction.resetUI:
      return initialState;

    case UIAction.setBrandNav:
      return {
        ...state,
        brandNavOpen: !!action.payload,
      };

    case UIAction.setLocaleNav:
      return {
        ...state,
        localeNavOpen: !!action.payload,
      };

    case UIAction.setPageTransition:
      return {
        ...state,
        pageTransitionActive: !!action.payload,
      };

    default:
      return { ...state };
  }
}

export const UIContext = createContext<UIContextProps | undefined>(undefined);

interface UIProviderProps {
  path: string;
}

export const UIProvider: React.FC<UIProviderProps> = ({ children, path }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const lockScroll = state.brandNavOpen || state.pageTransitionActive || state.localeNavOpen;
  useBodyLock(lockScroll);

  const resetUI = useCallback(() => {
    dispatch({
      type: UIAction.resetUI,
    });
  }, []);

  const openBrandNav = useCallback(() => {
    dispatch({
      type: UIAction.setBrandNav,
      payload: true,
    });
  }, []);

  const closeBrandNav = useCallback(() => {
    dispatch({
      type: UIAction.setBrandNav,
      payload: false,
    });
  }, []);

  const toggleBrandNav = useCallback(() => {
    dispatch({
      type: UIAction.setBrandNav,
      payload: !state.brandNavOpen,
    });
  }, [state.brandNavOpen]);

  const openLocaleNav = useCallback(() => {
    dispatch({
      type: UIAction.setLocaleNav,
      payload: true,
    });
  }, []);

  const closeLocaleNav = useCallback(() => {
    dispatch({
      type: UIAction.setLocaleNav,
      payload: false,
    });
  }, []);

  const toggleLocaleNav = useCallback(() => {
    dispatch({
      type: UIAction.setLocaleNav,
      payload: !state.localeNavOpen,
    });
  }, [state.localeNavOpen]);

  const notifyPageTransitionStarted = useCallback(() => {
    dispatch({
      type: UIAction.setPageTransition,
      payload: true,
    });
  }, []);

  const notifyPageTransitionEnded = useCallback(() => {
    dispatch({
      type: UIAction.setPageTransition,
      payload: false,
    });
  }, []);

  useEffect(() => {
    notifyPageTransitionEnded();
    closeBrandNav();
  }, [closeBrandNav, notifyPageTransitionEnded, path]);

  return (
    <UIContext.Provider
      value={{
        ...state,
        resetUI,
        openBrandNav,
        closeBrandNav,
        toggleBrandNav,
        openLocaleNav,
        closeLocaleNav,
        toggleLocaleNav,
        notifyPageTransitionStarted,
        notifyPageTransitionEnded,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
