import type { Asset } from "~/types/asset";

/**
 * Check if the image is a GIF.
 */
export const isGif = (image: Asset): boolean => image.content_type === "image/gif";

/**
 * Check if the image is a SVG.
 */
export const isSVG = (image: Asset): boolean => image.content_type === "image/svg+xml";

/**
 * Check if the image is a JPG, PNG, or WEBP.
 */
export const isImage = ({ content_type }: Asset): boolean => {
  return content_type === "image/jpeg" || content_type === "image/png" || content_type === "image/webp";
};

/**
 * Utility to check if asset is an MP4 or WEBM
 * */
export const isVideo = ({ content_type }: Asset): boolean => {
  return content_type === "video/mp4" || content_type === "video/webm";
};
