import React, { useRef, useEffect, useState, ReactNode } from "react";
// modern-normalize is a set of global styles that reset browsers' default style.
import "modern-normalize/modern-normalize.css";
import { StyleSheetManager } from "styled-components";
import stylisRTLCSS from "stylis-rtlcss";
import ThemeProvider from "~/context/theme";
import { ChapterContextProvider } from "~/context/chapter";
import { UIProvider } from "~/context/ui";
import Header from "~/components/Header";
import Seo from "~/components/Seo";
import { useEventListener } from "~/helpers/hooks/useEventListener";
import { GlobalStyles } from "~/styles/global";
import type { PageProps } from "./types";
import favicon from "~/images/favicon.png";

/**
 * Default Page component wrapping the site. Includes {@link GlobalStyles global styles}
 * and shared components such as the {@link Header}.
 */
const Page = ({ children, data, pageContext: { language }, path }: PageProps): JSX.Element => {
  const mainRef = useRef<HTMLElement | null>(null);
  const [currentChildren, setCurrentPage] = useState<ReactNode | null>(null);
  useEffect(() => {
    document.documentElement.style.setProperty("--viewport-height-initial", `${window.innerHeight}px`);
  }, []);

  // `--viewport-height` is also `100vh` by default, but will change when
  // `window.innerHeight` is updated. This should be used for fixed elements
  // that have areas that might get cropped by a mobile browser's UI.
  useEventListener(
    "resize",
    () => document.documentElement.style.setProperty("--viewport-height", `${window.innerHeight}px`),
    { initial: true },
  );

  const { meta, header, page } = data || {};
  const [brand] = page?.brand || [];
  const previousBrand = useRef<string | null>(brand?.slug);

  // TEMPORARY:
  // check if page is 404 page. if so, default to leage of legends theme
  const is404 = page?.__typename === "Contentstack_404";
  const themeBrand = is404 ? "league-of-legends" : brand?.slug || "default";

  // Delay rendering of new page when switching brands. This gives the theme
  // provider time to switch colors and fonts without content flashing.
  useEffect(() => {
    const applyChildren = () => {
      setCurrentPage(children);
      if (mainRef.current) mainRef.current.style.opacity = "1";
    };

    const delayRender = brand?.slug && previousBrand.current !== brand?.slug;
    previousBrand.current = brand?.slug || null;

    if (delayRender) {
      setCurrentPage(null);
      if (mainRef.current) {
        mainRef.current.style.opacity = "0";
        mainRef.current.style.transition = "opacity 0.3s ease";
      }
      const timeout = window.setTimeout(applyChildren, 300);
      return () => window.clearTimeout(timeout);
    } else {
      applyChildren();
    }

    // useEffect dependencies intentionally missing suggested dependencies
    // to ensure that the effect is only ran when the "brand" object changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  // data is not defined for the default /404/ page
  // data.page is not defined for gatsby's dev 404 page.
  if (!page) return <>{children}</>;

  const isRTL = language === "ar-ae";

  const seoData = {
    favicon: brand?.favicon?.url || favicon,
    site: meta,
    brand: brand?.meta,
    page: page?.meta,
  };

  return (
    <ChapterContextProvider brand={brand} chapter={page}>
      <UIProvider path={path}>
        <ThemeProvider brand={themeBrand} language={language}>
          {/* Custom styled-components stylesheet manager, allows for RTL support. */}
          <StyleSheetManager stylisPlugins={isRTL ? [stylisRTLCSS] : []}>
            <>
              <Seo data={seoData} language={language} dir={isRTL ? "rtl" : ""} />
              {/* Global styles for html, body, etc. */}
              <GlobalStyles />
              <Header {...header} />
              <main ref={mainRef}>{currentChildren}</main>
            </>
          </StyleSheetManager>
        </ThemeProvider>
      </UIProvider>
    </ChapterContextProvider>
  );
};

export default Page;
