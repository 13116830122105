import { pxToRem } from "~/helpers/pxToRelativeUnit";
import type { ThemeDefinition, ThemeColors, ThemeTypeStyles, ThemeSpace, ThemeOptions } from "./types";

const name = "league-of-legends";

const options: ThemeOptions = {
  uppercaseHeadings: true,
};

const brandColors = {
  hextechBlack: "#010A13",
  coolGrey: "#1E282D",
  grey1: "#A09B8C",
  grey1_5: "#5B5A56",
  grey2: "#3C3C41",
  grey3: "#1E2328",
  gold1: "#F0E6D2",
  gold4: "#C89B3C",
  gold5: "#785A28",
  gold6: "#463714",
};

const colors: ThemeColors = {
  background: brandColors.hextechBlack,
  foreground: brandColors.gold1,
  primary: brandColors.gold1,
  secondary: brandColors.gold4,
  accent: brandColors.gold5,
  body: brandColors.grey1,
  utility: {
    primary: brandColors.grey1_5,
    secondary: brandColors.grey2,
  },
  border: {
    primary: brandColors.gold6,
    secondary: brandColors.gold4,
  },
  nav: {
    background: brandColors.hextechBlack,
    border: brandColors.grey3,
    primary: brandColors.hextechBlack,
    secondary: brandColors.coolGrey,
    subnav: brandColors.coolGrey,
    foreground: brandColors.gold1,
  },
  button: {
    primary: {
      text: brandColors.gold1,
      border: brandColors.gold5, // theme.colors.button.primary.hover.border
      background: brandColors.hextechBlack,
      hover: {
        text: brandColors.gold1,
        border: brandColors.gold4,
        background: brandColors.hextechBlack,
      },
    },
    secondary: {
      text: brandColors.gold1,
      border: brandColors.gold5,
      background: brandColors.gold5,
      hover: {
        text: brandColors.gold1,
        border: brandColors.gold4,
        background: brandColors.gold4,
      },
    },
    link: {
      text: brandColors.gold1,
      border: brandColors.gold5,
      background: brandColors.hextechBlack,
      hover: {
        text: brandColors.gold1,
        border: brandColors.gold4,
        background: brandColors.gold4,
      },
    },
  },
};

const space: ThemeSpace = {
  none: pxToRem(0),
  "2xs": pxToRem(6),
  xs: pxToRem(8),
  sm: pxToRem(12),
  base: pxToRem(16),
  lg: pxToRem(20),
  xl: pxToRem(24),
  "2xl": pxToRem(28),
  "3xl": pxToRem(32),
  "4xl": pxToRem(40),
  "5xl": pxToRem(60),
  "6xl": pxToRem(70),
  "7xl": pxToRem(80),
  "8xl": pxToRem(120),
  "9xl": pxToRem(140),
  "10xl": pxToRem(240),
  "11xl": pxToRem(300),
};

const fontStyleSheets = [
  "https://lolstatic-a.akamaihd.net/webfonts/live/css/fonts/beaufortforlol.css",
  "https://lolstatic-a.akamaihd.net/webfonts/live/css/fonts/spiegel.css",
];

const fonts = {
  heading: "Beaufort for LOL",
  body: "Spiegel",
};

const typeStyles: ThemeTypeStyles = {
  "heading-xs": {
    fontFamily: "heading",
    fontSize: pxToRem(12),
    lineHeight: 1,
    letterSpacing: "0.04em",
    fontWeight: 700,
  },
  "heading-sm": {
    fontFamily: "heading",
    fontSize: pxToRem(18),
    lineHeight: 1.12,
    letterSpacing: "0.025em",
    fontWeight: 700,
  },
  "heading-base": {
    fontFamily: "heading",
    fontSize: pxToRem(24),
    lineHeight: 1.12,
    letterSpacing: "0.02em",
    fontWeight: 700,
  },
  "heading-lg": {
    fontFamily: "heading",
    fontSize: pxToRem(28),
    lineHeight: 1.08,
    letterSpacing: "0.01em",
    fontWeight: 700,
  },
  "heading-xl": {
    fontFamily: "heading",
    fontSize: pxToRem(32),
    lineHeight: 1.08,
    letterSpacing: "0.01em",
    fontWeight: 700,
  },
  "heading-2xl": {
    fontFamily: "heading",
    fontSize: pxToRem(40),
    lineHeight: 1.08,
    letterSpacing: "0em",
    fontWeight: 700,
  },
  "heading-3xl": {
    fontFamily: "heading",
    fontSize: pxToRem(48),
    lineHeight: 1.08,
    letterSpacing: "0em",
    fontWeight: 700,
  },
  "heading-4xl": {
    fontFamily: "heading",
    fontSize: pxToRem(60),
    lineHeight: 1.08,
    letterSpacing: "0em",
    fontWeight: 700,
  },
  "heading-5xl": {
    fontFamily: "heading",
    fontSize: pxToRem(90),
    lineHeight: 1.08,
    letterSpacing: "-0.01em",
    fontWeight: 700,
  },
  "body-xs": {
    fontFamily: "body",
    fontSize: pxToRem(10),
    lineHeight: 1,
    letterSpacing: "0.04em",
  },
  "body-sm": {
    fontFamily: "body",
    fontSize: pxToRem(14),
    lineHeight: 1.26,
    letterSpacing: "0.02em",
  },
  "body-base": {
    fontFamily: "body",
    fontSize: pxToRem(16),
    lineHeight: 1.24,
    letterSpacing: "0.01em",
  },
  button: {
    fontFamily: "body",
    fontSize: pxToRem(12),
    lineHeight: 1.26,
    letterSpacing: "0.1em",
  },
  "nav-link": {
    fontFamily: "heading",
    fontSize: pxToRem(12),
    fontWeight: 700,
    lineHeight: 1.26,
    letterSpacing: "0.06em",
  },
};

const cursors = {
  default: `url(/images/league-of-legends/cursor.png), auto`,
  pointer: `url(/images/league-of-legends/cursor-pointer.png), auto`,
  drag: `url(/images/league-of-legends/cursor-drag.png) , ew-resize`,
};

const decorators = {
  rule: {
    small: "/images/league-of-legends/decorator-hr.png",
    large: "/images/league-of-legends/decorator-hr-lg.png",
  },
  label: {
    left: "/images/league-of-legends/decorator-left.png",
    right: "/images/league-of-legends/decorator-right.png",
  },
  bullet: "/images/league-of-legends/decorator-bullet.svg",
  nextChapter: "/images/league-of-legends/decorator-next-chapter.png",
};

const localeOverrides = {
  "ja-jp": {
    fontStyleSheets: [
      "https://lolstatic-a.akamaihd.net/webfonts/live/css/fonts/spiegelja.css",
      "https://lolstatic-a.akamaihd.net/webfonts/live/css/fonts/beaufortforlolja.css",
    ],
    fonts: {
      heading: "Beaufort for LOL Ja",
      body: "SpiegelJa",
    },
  },
};

const theme: ThemeDefinition = {
  name,
  options,
  colors,
  space,
  fonts,
  fontStyleSheets,
  typeStyles,
  decorators,
  cursors,
  localeOverrides,
};

export default theme;
