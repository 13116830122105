export const localeNames: Record<string, string> = {
  "en-us": "English (US)",
  "en-gb": "English (Great Britain)",
  "en-sg": "English (Singapore)",
  "en-au": "English (Australia)",
  "en-ph": "English (Philippines)",
  "en-pl": "English (EUNE)",
  "de-de": "Deutsch",
  "fr-fr": "Français",
  "it-it": "Italiano",
  "es-es": "Español (EUW)",
  "pl-pl": "Polski",
  "cs-cz": "Čeština",
  "el-gr": "Ελληνικά",
  "hu-hu": "Magyar",
  "ro-ro": "Română",
  "es-mx": "Español (Latinoamérica)",
  "ja-jp": "日本語",
  "ko-kr": "한국어",
  "tr-tr": "Türkçe",
  "es-ar": "Argentinian",
  "pt-br": "Brazilian Portuguese",
  "ru-ru": "Русский",
  "ar-ae": "Arabic",
  "id-id": "Bahasa Indonesia",
  "vi-vn": "Tiếng Việt",
  "th-th": "Thai",
  // "ms-my": "Bahasa Malaysia",
  // "zh-my": "Malaysia (Simplied Chinese)",
  // "zh-cn": "中文 (简体字)",
  "zh-tw": "Traditional Chinese (Taiwan)",
};
