module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/mnt/nfs/wwpub/static_sites/riot-brand-hub/riot-brand-hub-static-green/persistent/src/components/Page/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-us","en-gb","en-sg","en-au","en-ph","de-de","fr-fr","it-it","es-es","pl-pl","cs-cz","el-gr","hu-hu","ro-ro","es-mx","ja-jp","ko-kr","tr-tr","pt-br","es-ar","ru-ru"],"defaultLanguage":"en-us","generateDefaultLanguagePage":true,"siteUrl":"https://static-staging.rgpub.io/gatsbinger/","redirect":false,"i18nextOptions":{"initImmediate":false,"interpolation":{"escapeValue":false},"load":"currentOnly","keySeparator":".","nsSeparator":":","returnObjects":true,"lowerCaseLng":true,"cleanCode":true,"react":{"useSuspense":true}},"pages":[{"matchPath":"/:lang?","getLanguageFromPath":true,"excludeLanguages":["en-us","en-gb","en-sg","en-au","en-ph","de-de","fr-fr","it-it","es-es","pl-pl","cs-cz","el-gr","hu-hu","ro-ro","es-mx","ja-jp","ko-kr","tr-tr","pt-br","es-ar","ru-ru"]},{"matchPath":"/:lang?/:brand","getLanguageFromPath":true,"excludeLanguages":["en-us","en-gb","en-sg","en-au","en-ph","de-de","fr-fr","it-it","es-es","pl-pl","cs-cz","el-gr","hu-hu","ro-ro","es-mx","ja-jp","ko-kr","tr-tr","pt-br","es-ar","ru-ru"]},{"matchPath":"/:lang?/:brand/:chapter","getLanguageFromPath":true,"excludeLanguages":["en-us","en-gb","en-sg","en-au","en-ph","de-de","fr-fr","it-it","es-es","pl-pl","cs-cz","el-gr","hu-hu","ro-ro","es-mx","ja-jp","ko-kr","tr-tr","pt-br","es-ar","ru-ru"]}]},
    },{
      plugin: require('../node_modules/@riotgames/gatsby-plugin-geotargeting/gatsby-browser.js'),
      options: {"plugins":[],"supportedLocales":["en-us","en-gb","en-sg","en-au","en-ph","de-de","fr-fr","it-it","es-es","pl-pl","cs-cz","el-gr","hu-hu","ro-ro","es-mx","ja-jp","ko-kr","tr-tr","pt-br","es-ar","ru-ru"],"defaultLanguage":"en-gb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
