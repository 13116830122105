import React, { useCallback, useRef, useEffect } from "react";
import { useScrollEventListener } from "~/helpers/hooks/useScrollEventListener";
import type { UseScrollEventListenerCallback } from "~/helpers/hooks/useScrollEventListener";
import { useTheme } from "styled-components";
import { useMatchMedia } from "~/helpers/hooks/useMatchMedia";
import { useChapterContext } from "~/helpers/hooks/useChapterContext";
import type { ChapterContextProviderProps } from "~/context/chapter";
import { Box } from "~/components/Layout/Box";
import DesktopBrandNav from "./DesktopBrandNav";
import { ProgressBar } from "./styled";
import MobileBrandNav from "./MobileBrandNav";

const BrandNav: React.FC<{ headerCollapsed: boolean }> = ({ headerCollapsed }) => {
  const {
    brand,
    chapter: currentChapter,
    currentChapterIndex,
    hasNextChapter,
  } = useChapterContext() as ChapterContextProviderProps;
  const { chapters } = brand || {};
  const { mediaQueries } = useTheme();
  const isNotMobile = useMatchMedia(mediaQueries.md);
  const progressBarRef = useRef<HTMLDivElement | null>(null);

  const handleScroll: UseScrollEventListenerCallback = useCallback(
    ({ scrollTop }) => {
      const chapterContents = document.querySelector("#chapterContents") as HTMLDivElement;
      // the total scroll height of a chapter page is different for non-final chapters.
      const totalScroll =
        hasNextChapter && chapterContents
          ? chapterContents.offsetHeight
          : document.body.offsetHeight - window.innerHeight;

      if (progressBarRef.current) {
        let currentProgress = Math.min(1, scrollTop / totalScroll);
        if (isNotMobile) {
          const chapterPercent = 1 / chapters.length;
          currentProgress = currentChapterIndex * chapterPercent + currentProgress * chapterPercent;
        }
        progressBarRef.current.style.transform = `scaleX(${currentProgress})`;
      }
    },
    [chapters?.length, currentChapterIndex, isNotMobile, hasNextChapter],
  );

  useScrollEventListener(handleScroll, true);

  useEffect(() => {
    if (progressBarRef.current) {
      // allow the progress bar to smoothly transition between states while the chapter page is being updated
      progressBarRef.current.classList.add("transitionProgress");
      const timeout = window.setTimeout(() => {
        if (progressBarRef.current) {
          progressBarRef.current.classList.remove("transitionProgress");
        }
      }, 300);
      return () => window.clearTimeout(timeout);
    }
  }, [currentChapter]);

  useEffect(() => {
    handleScroll({ scrollProgress: 0, scrollTop: 0 });
  }, [handleScroll]);

  if (!brand || !currentChapter) return null;

  return (
    <Box width="100%" height="100%">
      {isNotMobile ? <DesktopBrandNav /> : <MobileBrandNav headerCollapsed={headerCollapsed} />}
      <ProgressBar ref={progressBarRef} />
    </Box>
  );
};

export default BrandNav;
