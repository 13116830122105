import React from "react";
import type { ForwardedRef } from "react";
import styled, { css } from "styled-components";
import {
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  flex,
  FlexProps,
  ResponsiveValue,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
  style,
  styleFn,
  TLengthStyledSystem,
} from "styled-system";
import { TypeSizes } from "~/styles/themes/types";

export interface TextProps
  extends SpaceProps,
    LayoutProps,
    FlexProps,
    Omit<TypographyProps, "textAlign">,
    Omit<ColorProps, "color"> {
  as?: string | React.ElementType;
  role?: string;
  children?: React.ReactNode;
  typeStyle?: ResponsiveValue<TypeSizes>;
  // 'color' and 'textAlign' clashes with @types/react, which thinks they are
  // DOM attributes and expects a string. This is a temporary workaround.
  textColor?: ResponsiveValue<string>;
  textAlign?: ResponsiveValue<TLengthStyledSystem>;
}

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors",
});

const textSystem = compose(color, layout, flex, space, typography, textColor);

// Enable uppercasing of headings when supported.
const uppercaseHeadings: styleFn = ({ theme, typeStyle }) => {
  const hasUppercaseHeadings = theme.uppercaseHeadings && theme.supportsUppercasing;
  if (/^heading(.*)|nav-link/i.test(typeStyle)) {
    return css`
      text-transform: ${hasUppercaseHeadings ? "uppercase" : "none"};
    `;
  }
};

const textVariants = variant({
  prop: "typeStyle",
  scale: "typeStyles",
  variants: { default: {} },
});

const StyledText = styled.p<TextProps>(textSystem, textVariants, uppercaseHeadings);

/**
 * Generic Text component. Use the `typeStyle` prop to render different styles
 * defined in `theme.typeStyles`.
 */
const Text = <T extends TextProps>(props: T, ref: ForwardedRef<HTMLParagraphElement>): JSX.Element | null => {
  if (!props.children) return null;

  return <StyledText ref={ref} {...props} />;
};

const RefText = React.forwardRef<HTMLParagraphElement, TextProps>(Text);
RefText.displayName = "Text";

export default RefText;
