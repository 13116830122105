import styled, { keyframes } from "styled-components";
import { Link } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import { Container } from "~/components/Layout";
import { headerPrimaryHeight, headerSecondaryHeight, headerHeight } from "./constants";
import css from "@styled-system/css";

export const HeaderContainer = styled.header<{ $collapsed?: boolean; $hasBrandNav: boolean }>`
  height: ${({ $hasBrandNav }) => ($hasBrandNav ? headerHeight : headerPrimaryHeight)}px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: transform 0.3s ease, height 0.3s ease;
  transform: translateY(${({ $collapsed }) => ($collapsed ? -headerPrimaryHeight : 0)}px);
  cursor: ${({ theme }) => theme.cursors.default};
`;

export const MainNav = styled.nav`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.utility.secondary};
  height: ${headerPrimaryHeight}px;
  background-color: ${({ theme }) => theme.colors.nav.background};
  color: ${({ theme }) => theme.colors.nav.foreground};
  z-index: 2;
`;

export const MainNavInner = styled(Container)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const SubNav = styled(motion.div)`
  position: relative;
  height: ${headerSecondaryHeight}px;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: -1;
`;

export const MainNavLink = styled(Link)`
  transition: background-color 0.3s ease;
  background-color: ${({ theme }) => theme.colors.nav.background};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.nav.secondary};
    }
  }
`;

export const LogoLink = styled(MainNavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1.125rem;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.utility.secondary};
  align-items: flex-start;
  ${({ theme }) =>
    css({
      px: theme.grid.margins,
      marginLeft: Array.isArray(theme.grid.margins)
        ? theme.grid.margins.map((margin) => `-${margin}`)
        : `-${theme.grid.margins}`,
    })()}

  & img {
    width: auto;
    height: 33%;
  }
`;

export const BrandLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 2rem;
  height: 100%;
`;

const reveal = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const BrandLogoLink = styled(MainNavLink)`
  display: flex;
  margin-left: -1.125rem;
  padding: 0 1.125rem;
  min-height: 100%;
  align-items: center;
  animation: ${reveal} 0.5s 0.5s ease;
  animation-fill-mode: both;
`;

export const DownloadsLink = styled(MainNavLink)`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.secondary};
`;
