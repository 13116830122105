import { useEffect } from "react";

export function useBodyLock(lock = true): void {
  useEffect(() => {
    if (lock) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [lock]);
}
