import { CSSObject } from "styled-components";

export function hoverState(cssObject: CSSObject): CSSObject {
  return {
    "@media (hover: hover)": {
      "&:hover": {
        ...cssObject,
      },
    },
  };
}
