function pxToRelativeUnit(unit: "em" | "rem") {
  return (value: string | number): string => {
    const _value = typeof value === "string" ? parseFloat(value) : value;

    return (_value / 16).toString() + unit;
  };
}

export const pxToEm = pxToRelativeUnit("em");
export const pxToRem = pxToRelativeUnit("rem");
