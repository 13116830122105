import { useEffect, useRef } from "react";

interface useEventListenerOptions {
  target?: HTMLElement;
  initial?: boolean;
}

type EventListener = (event: Event | undefined) => void;

/**
 * Hook for creating event listeners.
 * @param {string} eventType - type of event to listen for
 * @param {function} callback - event handler callback method
 * @param {Object} [options] - options object
 * @param {Object} [options.target=window] - the event target element. If null,
 *   window will be used as the target.
 * @param {boolean} [options.initial=false] - If true, the callback method will
 *   be called once on initilization. Note: callback should handle not having an
 *   event object passed to it.
 */
export function useEventListener(
  eventType: string | null,
  callback: EventListener,
  options?: useEventListenerOptions,
): void {
  const { target, initial = false } = options || ({} as useEventListenerOptions);
  const callbackRef = useRef<EventListener | null>(null);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (eventType) {
      const targetElement = target || window;
      const eventListener = (event?: Event) => {
        if (callbackRef.current) {
          callbackRef.current(event);
        }
      };

      if (initial) eventListener();
      // Attach event to target
      targetElement.addEventListener(eventType, eventListener);
      // Remove event on cleanup
      return () => targetElement.removeEventListener(eventType, eventListener);
    }
  }, [eventType, initial, target]);
}
