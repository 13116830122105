import React from "react";
import Icon from "~/components/Icon";
import { useUI } from "~/helpers/hooks/useUI";
import { LocaleNavButtonWrapper } from "./styled";

const LocaleNavButton: React.FC = () => {
  const { toggleLocaleNav, localeNavOpen } = useUI();

  return (
    <LocaleNavButtonWrapper $localeNavOpen={localeNavOpen} onClick={toggleLocaleNav}>
      <Icon type="locale" height={14} />
    </LocaleNavButtonWrapper>
  );
};

export default LocaleNavButton;
