import React, { useEffect } from "react";
import { detectLocale } from "./helpers/localeDetection";
import { GeotargeterPluginOptions, LOCALE_REGEX } from "./page-wrapper";

function redirectToLocale(locale: string): void {
  const { pathname: currentPath, origin, search } = window.location;
  const targetURL = `${origin}/${locale}${currentPath}${search}`;
  const found = currentPath.match(LOCALE_REGEX);

  if (!found) {
    window.location.replace(targetURL);
  } else {
    console.warn(`Warning: attempting to redirect ${currentPath} to ${targetURL}.`);
  }
}
export const Geotargeter: React.FC<GeotargeterPluginOptions> = (props) => {
  useEffect(() => {
    // chooseLocale uses this order to determine locale: localStorage set by previous visit || geo cookie set by akamai || Accept-Language || browser language
    const autodetectedLang = detectLocale(props, props.defaultLanguage);
    console.log(`autodetectedlang: ${autodetectedLang}`);
    redirectToLocale(autodetectedLang);
  }, [props]);

  return null;
};

export default Geotargeter;
