import locale from "locale";
import { ClientStorage } from "../configs/clientStorage";
import cookie from "./cookie";
import { getCountryProperties, countryConfig } from "../configs/countryConfig";
import { browserAcceptLanguageTobcpLocale } from "../configs/languageConfig";
import { supportedLocales } from "../configs/supportedLocales";
import { GeotargeterPluginOptions, LanguageConfig, CountryConfig } from "../page-wrapper";

const detectFromGeoCookie = (countryMapping: CountryConfig, validLocales: Array<string>): string | undefined => {
  let found;
  const cookieValue = cookie.read(ClientStorage.COOKIE_REGION)?.toUpperCase();

  if (cookieValue && Object.keys(countryMapping).includes(cookieValue)) {
    // match country code in geo cookie to a locale in country-to-locale mapping defined by Product Owner
    const matches = countryMapping[cookieValue.toUpperCase()].preferredLocales;
    const bestMatch = matches?.find((match: string) => validLocales.includes(match));

    if (bestMatch && !countryMapping[cookieValue.toUpperCase()].skipGeoCookieCheck) {
      found = bestMatch;
    }
  }

  return found;
};

const detectFromAcceptLanguage = (languageMapping: LanguageConfig, validLocales: Array<string>): string | undefined => {
  let found;

  const cookieValue =
    cookie.read(ClientStorage.COOKIE_ACCEPT_LANGUAGE_OVERRIDE) || cookie.read(ClientStorage.COOKIE_ACCEPT_LANGUAGE);

  if (cookieValue !== null) {
    // use locale package to find locale that best matches lang cookie value
    const locales = new locale.Locales(validLocales, process.env.GATSBY_DEFAULT_LOCALE);
    const langLocale = new locale.Locales(cookieValue, process.env.GATSBY_DEFAULT_LOCALE);
    const bestGuess = locales.best(langLocale);
    if (bestGuess) {
      found = bestGuess.code.toLowerCase();
    }
  } else {
    // match browser language to a locale in browser-language-to-locale mapping defined by Product Owner
    found = languageMapping[navigator.language.toLocaleLowerCase()];
  }

  return found;
};

export const detectLocale = (props: GeotargeterPluginOptions, defaultLocale: string): string => {
  if (typeof window === "undefined") {
    return defaultLocale;
  }

  let found: string | undefined;

  // incorporate overrides from plugin options (if any)
  const countryMapping = Object.assign({}, countryConfig, props.countryConfig);
  const languageMapping = Object.assign({}, browserAcceptLanguageTobcpLocale, props.languageConfig);
  const validLocales = props.supportedLocales ? props.supportedLocales : supportedLocales;

  const countryProps = getCountryProperties(countryMapping);

  // check localStorage for locale; unset localStorage if locale is not supported
  if (typeof window !== "undefined") {
    const localStorageDefinedLanguage = window.localStorage.getItem(ClientStorage.LOCALSTORAGE_DEFINED_LANGUAGE);
    if (localStorageDefinedLanguage && supportedLocales.includes(localStorageDefinedLanguage)) {
      found = localStorageDefinedLanguage;
    }
  }
  const fromHistory = !!found;

  // check geo cookie set by Akamai for locale
  if (!found) {
    found = detectFromGeoCookie(countryMapping, validLocales);
  }

  // check Accept-Language header and browser language setting for locale
  if (!found) {
    found = detectFromAcceptLanguage(languageMapping, validLocales);
  }

  // if all else fails, use defaultLocale
  if (!found) {
    found = defaultLocale;
  }

  // format locale
  const autodetected = found.replace("_", "-").toLowerCase();
  const resolvedLocale = props.supportedLocales.includes(autodetected) ? autodetected : defaultLocale;

  // set localStorage if locale is NOT governed by GDPR since GDPR forbids it.
  if (!fromHistory && !countryProps.gdpr) {
    window.localStorage.setItem(ClientStorage.LOCALSTORAGE_DEFINED_LANGUAGE, resolvedLocale);
  }

  return resolvedLocale;
};
