import { supportsUppercasing } from "~/helpers/rioti18n";
import { pxToEm, pxToRem } from "~/helpers/pxToRelativeUnit";
import type * as Types from "./types";

// Defined themes
import defaultTheme from "./default";
import leagueOfLegendsTheme from "./league-of-legends";
import { hoverState } from "~/helpers/hoverState";
import { responsiveAlias } from "./helpers";

// Breakpoints dictionary
const breakpoints: Types.Breakpoints = {
  sm: pxToEm(640),
  md: pxToEm(960),
  lg: pxToEm(1280),
};

const mediaQueries: Types.MediaQueries = {
  sm: `(min-width: ${breakpoints.sm})`,
  md: `(min-width: ${breakpoints.md})`,
  lg: `(min-width: ${breakpoints.lg})`,
};

const margins: Types.Breakpoints = {
  sm: pxToRem(20),
  md: pxToRem(40),
  lg: pxToRem(40),
};

const columnGap: Types.Breakpoints = {
  sm: pxToRem(20),
  md: pxToRem(40),
  lg: pxToRem(40),
};

/** Variants for Button component's 'variant' prop */
export const buttonColors = {
  primary: {
    color: "button.primary.text",
    backgroundColor: "button.primary.background",
    borderColor: "button.primary.border",
    ...hoverState({
      color: "button.primary.hover.text",
      backgroundColor: "button.primary.hover.background",
      borderColor: "button.primary.hover.border",
    }),
  },
  secondary: {
    color: "button.secondary.text",
    backgroundColor: "button.secondary.background",
    borderColor: "button.secondary.border",
    ...hoverState({
      color: "button.secondary.hover.text",
      backgroundColor: "button.secondary.hover.background",
      borderColor: "button.secondary.hover.border",
    }),
  },
  link: {
    color: "button.link.text",
    backgroundColor: "button.link.background",
    borderColor: "button.link.border",
    ...hoverState({
      color: "button.link.hover.text",
      backgroundColor: "button.link.hover.background",
      borderColor: "button.link.hover.border",
    }),
  },
};

/** Variants for Button component's 'styleVariant' prop */
export const buttonStyles = {
  default: {
    padding: "0.75rem 1.5rem",
    border: "2px solid",
  },
  link: {
    padding: "0",
    paddingBottom: "0.375rem",
    border: "0",
    borderBottom: "2px solid",
  },
};

// Default theme constructor
export class Theme {
  readonly name: string;
  readonly language: string;
  readonly colors: Types.ThemeColors;
  readonly fontStyleSheets: string[];
  readonly fonts: Types.Fonts;
  readonly space: Types.ThemeSpace;
  readonly typeStyles: Types.ThemeTypeStyles;
  readonly decorators: Types.ThemeDecorators;
  readonly cursors: Types.ThemeCursors;
  readonly supportsUppercasing: boolean;
  readonly uppercaseHeadings: boolean;

  constructor(params: Types.ThemeConstructor) {
    this.name = params.name;
    this.language = params.language;
    this.fontStyleSheets = params.fontStyleSheets;
    this.fonts = params.fonts;
    this.colors = params.colors;
    this.space = responsiveAlias(params.space);
    this.typeStyles = params.typeStyles;
    this.decorators = params.decorators;
    this.cursors = params.cursors;
    this.supportsUppercasing = supportsUppercasing(this.language);
    this.uppercaseHeadings = params.options.uppercaseHeadings;

    // Override theme settings for certain locales (if applicable)
    if (this.language in params.localeOverrides) {
      const overrides = params.localeOverrides[this.language];

      for (const key in overrides) {
        if (key in this) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Cannot infer correct typings
          this[key] = overrides[key];
        }
      }
    }
  }

  breakpoints = responsiveAlias(breakpoints);
  mediaQueries = responsiveAlias(mediaQueries);

  grid = {
    columns: 12,
    columnGap: responsiveAlias(columnGap),
    maxWidth: pxToRem(1360),
    margins: responsiveAlias(margins),
  };

  /**
   * Creates a min-width/max-width range based on the length of the supplied array.
   */
  mediaQueriesRange = (array: unknown[]): string[] => {
    if (array.length <= 1) return [];

    return array.map((_, index) => {
      switch (index) {
        case array.length - 1:
          return `(min-width: ${this.breakpoints[index - 1]})`;
        case 0:
          return `(min-width: 0) and (max-width: ${this.breakpoints[index]})`;
        default:
          return `(min-width: ${this.breakpoints[index - 1]}) and (max-width: ${this.breakpoints[index]})`;
      }
    });
  };

  breakpointsRange = this.mediaQueriesRange(this.breakpoints);
}

export function getTheme(name: string): Types.ThemeDefinition {
  switch (name) {
    case "league-of-legends":
      return leagueOfLegendsTheme;

    default:
      return defaultTheme;
  }
}
