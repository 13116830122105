import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { isGif, isSVG } from "~/components/Asset/helpers";
import { getAltText, useArtDirectedImages } from "./helpers";
import { ResponsiveImageWrapper } from "./styled";
import type { ContentstackImage } from "./types";

/**
 * Display a single dynamic Contentstack image.
 *
 * For the rest of the image props refer to: {@link https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#shared-props}
 */
export const Image = React.memo<ContentstackImage>(({ image, alt, ...rest }) => {
  if (!image) throw new Error("Image is undefined.");

  if (Array.isArray(image))
    throw new Error("An array was passed to the `Image` component. Did you mean to use ResponsiveImage?");

  if (isGif(image) || isSVG(image)) {
    return <img src={image.url} {...rest} />;
  }

  const gatsbyImage = getImage(image.localAsset);
  const altText = getAltText(image, alt);

  if (!gatsbyImage) throw new Error(`Could not get dynamic image from Contentstack: ${image.filename}`);

  return <GatsbyImage image={gatsbyImage} alt={altText} {...rest} />;
});

Image.displayName = "Image";

/**
 * Display responsive dynamic Contentstack images.
 *
 * @see {@link useArtDirectedImages}.
 *
 * @note Aspect ratios are patched via CSS (see {@link ResponsiveImage}).
 * This is due to a limitation with the `{@link withArtDirection}` function that
 * applies only the aspect ratio of the default image to the container.
 */
export const ResponsiveImage = React.memo<ContentstackImage>(({ image, alt, ...rest }) => {
  const { images, aspectRatios, altText } = useArtDirectedImages(image, alt);

  return <ResponsiveImageWrapper image={images} ratios={aspectRatios} alt={altText} {...rest} />;
});

ResponsiveImage.displayName = "ResponsiveImage";

/**
 * Display responsive dynamic Contentstack images as a background image. This
 * component differs from a normal `Image` in that it is a wrapper component and
 * requires children to display properly.
 *
 * @see {@link useArtDirectedImages}.
 */
export const BackgroundImage = React.memo<ContentstackImage>(({ image, children }) => {
  const { bgImages } = useArtDirectedImages(image);

  return <BgImage image={bgImages}>{children}</BgImage>;
});

BackgroundImage.displayName = "BackgroundImage";
