import styled, { css } from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

/**
 * Wrapper for GatsbyImage that applies the correct aspect ratios to
 * art-directed images. This is a limitation of the plugin.
 *
 * @see https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#withartdirection
 */
export const ResponsiveImageWrapper = styled(GatsbyImage)<{ ratios?: number[] }>`
  > div:first-child {
    ${({ theme, ratios }) =>
      ratios?.map(
        (ratio, i) => css`
          @media ${theme.breakpointsRange[i]} {
            padding-top: ${100 / ratio}% !important;
          }
        `,
      )}
  }
`;
