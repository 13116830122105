import { MotionProps, Variants } from "framer-motion";

export const headerPrimaryHeight = 70;
export const headerSecondaryHeight = 46;
export const headerHeight = headerPrimaryHeight + headerSecondaryHeight;
export const headerSafeHeight = `calc(var(--viewport-height) - ${headerHeight}px)`;
export const subNavVariants: Variants = {
  hidden: {
    y: "-100%",
  },
  visible: {
    y: "0",
  },
};

export const subNavMotionConfig: MotionProps = {
  initial: "hidden",
  animate: "visible",
  exit: "hidden",
  variants: subNavVariants,
  transition: {
    ease: "easeInOut",
  },
};
