import styled from "styled-components";
import css from "@styled-system/css";
import { motion } from "framer-motion";
import { Container } from "~/components/Layout/Container";
import { headerPrimaryHeight } from "~/components/Header/constants";

export const LocaleNavButtonWrapper = styled.div<{ $localeNavOpen: boolean }>`
  position: relative;
  height: 100%;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $localeNavOpen }) =>
    $localeNavOpen ? theme.colors.nav.secondary : theme.colors.nav.primary};
  transition: background-color 0.3s ease;
  border-left: 1px solid ${({ theme }) => theme.colors.utility.secondary};
  border-right: 1px solid ${({ theme }) => theme.colors.utility.secondary};
  color: ${({ theme }) => theme.colors.nav.foreground};

  & svg {
    pointer-events: none;
    transform: translateX(-0.5rem);
  }

  &::after {
    content: "▼";
    color: ${({ theme }) => theme.colors.secondary};
    position: absolute;
    left: calc(50% + 0.75rem);
    top: 50%;
    transform: translate(-50%, -50%) rotate(${({ $localeNavOpen }) => ($localeNavOpen ? -180 : 0)}deg);
    font-size: 0.75rem;
    z-index: 10;
    pointer-events: none;
    transition: transform 0.2s ease;
  }

  & select {
    appearance: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: transparent;
    color: transparent;

    @media (hover: hover) {
      &:hover {
        cursor: ${({ theme }) => theme.cursors.pointer};
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      cursor: ${({ theme }) => theme.cursors.pointer};
      background-color: ${({ theme }) => theme.colors.nav.subnav};
    }
  }
`;

export const LocaleNavMenuWrapper = styled(motion.div)`
  position: absolute;
  right: 0;
  top: ${headerPrimaryHeight}px;
  height: calc(100vh - ${headerPrimaryHeight}px);
  max-height: calc(100vh - ${headerPrimaryHeight}px);
  overflow: scroll;
  max-width: 100%;
  ${css({ width: ["100%", "auto"] })()}
`;

export const LocaleNavMenu = styled.nav`
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.nav.subnav};
  padding-bottom: 2.5rem;
`;

export const LocaleNavMenuItem = styled(Container)<{ $isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.utility.secondary};
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  font-size: 0.875rem;
  width: 100%;
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.secondary : theme.colors.nav.foreground)};

  & svg {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;
