import { useTheme } from "styled-components";
import { getImage, withArtDirection, IGatsbyImageData, IArtDirectedImage } from "gatsby-plugin-image";
import { isImage } from "~/components/Asset/helpers";
import type { Asset } from "~/types/asset";

/**
 * Helper to get alt text from Contentstack image "description", or fallback to
 * hard-coded alt text when available.
 */
export function getAltText(image: Asset | Asset[], fallback = ""): string {
  if (Array.isArray(image)) {
    // Get alt text of last ("default") image
    return image[image.length - 1].description || fallback;
  }
  return image.description || fallback;
}

/**
 * Hook for scaffolding art-directed image data. The `image` param must be a
 * single image object or an array of image objects. Arrays assume the images
 * are art directed, with the last item being the "default" image, and preceding
 * images respecting the first available breakpoints defined by the theme.
 *
 * For example, if your image field has 4 items:
 *
 * `[0 -> sm, sm -> md, md -> lg, lg +]`
 *
 * However, if it only has two items:
 *
 * `[0 -> sm, sm +]`
 */
export function useArtDirectedImages(
  image: Asset | Asset[],
  alt?: string,
): {
  images: IGatsbyImageData;
  bgImages: IGatsbyImageData;
  aspectRatios: number[];
  altText: string;
} {
  const { breakpointsRange } = useTheme();

  if (typeof image === "undefined") throw new Error("Image is undefined.");

  const images = Array.isArray(image) ? image : [image];

  const altText = getAltText(images, alt);

  // Map all images to GatsbyImageData.
  const gatsbyImages = images.reduce<IGatsbyImageData[]>((images, image) => {
    const gatsbyImage = getImage(image.localAsset);
    // `getImage` may be undefined.
    if (gatsbyImage) images.push(gatsbyImage);
    return images;
  }, []);

  // Grab the last image as the 'default'
  const defaultImage = gatsbyImages.pop();

  if (!defaultImage) throw new Error("Could not get dynamic image from Contentstack.");

  if (!images.some(isImage)) {
    throw new Error("GastbyImage does not support GIF or SVG. Use a normal Image tag.");
  }

  // Map remaining images to the IArtDirectedImage struct
  const responsiveImages = gatsbyImages.map<IArtDirectedImage>((image, index) => ({
    media: breakpointsRange[index],
    image,
  }));

  // Background images use a slightly different data structure...
  const backgroundImages = [
    defaultImage,
    ...gatsbyImages.map<IGatsbyImageData>((image, index) => ({
      media: breakpointsRange[index],
      ...image,
    })),
  ];

  // Get aspect ratios of all the images
  const aspectRatios = gatsbyImages.length > 0 ? gatsbyImages.map((image) => image.width / image.height) : [];

  return {
    images: withArtDirection(defaultImage, responsiveImages),
    // bgImages is typecast to unknown because of incorrect typings in `gbimage-bridge` plugin
    bgImages: (backgroundImages as unknown) as IGatsbyImageData,
    aspectRatios,
    altText,
  };
}
