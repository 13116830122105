import React, { KeyboardEventHandler, MouseEventHandler, useCallback } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { getChapterUrl } from "~/helpers/getChapterUrl";
import { ChapterContextProviderProps } from "~/context/chapter";
import { Box, Container } from "~/components/Layout";
import { Text } from "~/components/Text";
import { AnimatePresence } from "framer-motion";
import {
  subNavVariants,
  headerHeight,
  headerPrimaryHeight,
  headerSecondaryHeight,
} from "~/components/Header/constants";
import { useUI } from "~/helpers/hooks/useUI";
import { useChapterContext } from "~/helpers/hooks/useChapterContext";
import {
  MobileBrandNavWrapper,
  MobileBrandNavInner,
  MobileBrandNavItem,
  MobileBrandNavMenu,
  MobileBrandNavMenuItem,
} from "./styled";

const MobileBrandNav: React.FC<{ headerCollapsed: boolean }> = ({ headerCollapsed }) => {
  const { chapter: currentChapter, brand, currentChapterIndex } = useChapterContext() as ChapterContextProviderProps;
  const { brandNavOpen, closeBrandNav, toggleBrandNav } = useUI();
  const { t } = useTranslation();

  const { slug: brandSlug, chapters } = brand || {};

  const handleExpandMenu: MouseEventHandler = useCallback(
    (ev) => {
      ev.preventDefault();
      toggleBrandNav();
    },
    [toggleBrandNav],
  );

  // register a space key press as a click event to mimick button accessability behaviour
  const handleSpaceKey: KeyboardEventHandler = useCallback((ev) => {
    if (ev.code === "Space") (ev.target as HTMLButtonElement).click();
  }, []);

  if (!brandSlug || !chapters) return null;

  let nonContiguousNavItem;

  // handle non-contiguous chapters
  if (currentChapterIndex === -1) {
    nonContiguousNavItem = (
      <MobileBrandNavItem
        as={Link}
        to={getChapterUrl(brandSlug, currentChapter.slug)}
        onClick={handleExpandMenu}
        onKeyPress={handleSpaceKey}
        role="button"
        aria-label={t("a11y_chapter_menu_toggle")}
        $itemOffset={0}
        key={`brand-nav-item--${currentChapter.slug}`}
      >
        <Text as="span" typeStyle="nav-link">
          {currentChapter.meta.page_title}
        </Text>
      </MobileBrandNavItem>
    );
  }

  const brandNavMenuHeight = `calc(100vh - ${
    headerCollapsed ? headerPrimaryHeight - headerSecondaryHeight : headerHeight
  }px)`;

  return (
    <>
      <Box bg="background" overflow="hidden" width="100%" height="100%" zIndex={2} position="relative">
        <MobileBrandNavWrapper>
          <MobileBrandNavInner $brandNavOpen={brandNavOpen} aria-label={t("a11y_chapter_nav")}>
            {nonContiguousNavItem}
            {chapters.map((chapter, chapterIndex) => {
              const itemOffset = chapterIndex - currentChapterIndex;
              return (
                <MobileBrandNavItem
                  as={Link}
                  to={getChapterUrl(brandSlug, chapter.slug)}
                  onClick={itemOffset === 0 ? handleExpandMenu : undefined}
                  onKeyPress={itemOffset === 0 ? handleSpaceKey : undefined}
                  role={itemOffset === 0 ? "button" : "link"}
                  aria-label={itemOffset === 0 ? t("a11y_chapter_menu_toggle") : undefined}
                  $itemOffset={itemOffset}
                  aria-hidden={itemOffset < 0 || itemOffset > 1}
                  key={`brand-nav-item--${chapter.slug}`}
                >
                  <Text as="span" typeStyle="nav-link">
                    {chapter.meta.page_title}
                  </Text>
                </MobileBrandNavItem>
              );
            })}
          </MobileBrandNavInner>
        </MobileBrandNavWrapper>
      </Box>
      <AnimatePresence>
        {brandNavOpen && (
          <MobileBrandNavMenu
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={subNavVariants}
            transition={{ ease: "easeInOut" }}
            role="menu"
            style={{
              height: brandNavMenuHeight,
              overflowY: "scroll",
            }}
            onClick={closeBrandNav}
          >
            <Container onClick={(ev: MouseEvent | React.MouseEvent) => ev.stopPropagation()}>
              {chapters.map((chapter, chapterIndex) => {
                return (
                  <MobileBrandNavMenuItem
                    key={`brand-nav-item--${chapter.slug}`}
                    to={getChapterUrl(brandSlug, chapter.slug)}
                    $itemOffset={chapterIndex - currentChapterIndex}
                    role="menuitem"
                  >
                    <Text as="span" typeStyle="heading-sm">
                      {chapter.meta.page_title}
                    </Text>
                  </MobileBrandNavMenuItem>
                );
              })}
            </Container>
          </MobileBrandNavMenu>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileBrandNav;
