import { WrapPageElementNodeArgs } from "gatsby";

import * as React from "react";
import { Geotargeter } from ".";

export interface CountryConfig {
  [key: string]: {
    preferredLocales?: Array<string>;
    gdpr?: boolean;
    skipGeoCookieCheck?: boolean;
  };
}
export interface LanguageConfig {
  [key: string]: string;
}

export interface GeotargeterPluginOptions {
  supportedLocales: Array<string>;
  defaultLanguage: string;
  countryConfig?: CountryConfig;
  languageConfig?: LanguageConfig;
}
export const LOCALE_REGEX = /[a-z]{2}-[a-z]{2}/g;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidPluginOptions(obj: any): obj is GeotargeterPluginOptions {
  return (
    obj &&
    typeof obj === "object" &&
    obj?.supportedLocales?.every((l: unknown) => typeof l === "string" && l.match(LOCALE_REGEX)) &&
    // validate countryconfig
    (!obj.countryConfig || isValidCountryConfig(obj.countryConfig)) &&
    // validate languageConfig
    (!obj.languageConfig || isValidLanguageConfig(obj.languageConfig))
  );
}

function isValidCountryConfig(obj: any): obj is CountryConfig {
  return Object.values(obj).every(
    (v: any) =>
      v &&
      typeof v === "object" &&
      Array.isArray(v?.preferredLocales) &&
      typeof v?.preferredLocales.every((l: unknown) => typeof l === "string" && l.match(LOCALE_REGEX)),
  );
}

function isValidLanguageConfig(obj: any): obj is LanguageConfig {
  return Object.values(obj).every((v: any) => v && typeof v === "string" && typeof v.match(LOCALE_REGEX));
}

export function wrapPageElement({ element, props }: WrapPageElementNodeArgs, pluginOptions: unknown): object {
  if (props.location.pathname === "/") {
    if (!isValidPluginOptions(pluginOptions)) {
      throw new TypeError("Plugin options are not valid");
    }
    console.log("wrapping landing page with Geotargeter");
    return <Geotargeter {...pluginOptions} />;
  }
  return element;
}
