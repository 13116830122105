export const supportedLocales = [
  "ar-ae",
  "en-us",
  "en-gb",
  "es-es",
  "es-mx",
  "es-ar",
  "el-gr",
  "ja-jp",
  "tr-tr",
  "ko-kr",
];
