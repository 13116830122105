import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { Theme, getTheme } from "~/styles/themes";

interface ThemeContext {
  language: string;
  brand: string;
}

const ThemeProvider: React.FC<ThemeContext> = ({ children, language, brand }) => {
  const theme = useMemo(() => new Theme({ language, ...getTheme(brand) }), [language, brand]);

  return (
    <>
      <Helmet>
        {theme.fontStyleSheets.map((styleSheet) => (
          <link key={styleSheet} rel="stylesheet" href={styleSheet} />
        ))}
      </Helmet>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </>
  );
};

export default ThemeProvider;
