import cookie from "./cookie";
import { countryConfig } from "../configs/countryConfig";
import { ClientStorage } from "../configs/clientStorage";

export const getCountry = (): string => {
  const geo = (
    cookie.read(ClientStorage.COOKIE_REGION_OVERRIDE) ||
    cookie.read(ClientStorage.COOKIE_REGION) ||
    process.env.GATSBY_DEFAULT_LOCALE ||
    "en-gb"
  ).toUpperCase();

  if (Object.prototype.hasOwnProperty.call(countryConfig, geo)) {
    return geo;
  }
  return process.env.GATSBY_DEFAULT_LOCALE || "en-gb";
};
