import styled from "styled-components";
import { Container } from "~/components/Layout";
import { Link } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";

export const DesktopBrandNavWrapper = styled.nav`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.colors.border.primary};
`;

export const BrandNavItem = styled(Link)<{ $navItemState: number; $numChapters: number }>`
  height: 100%;
  width: ${({ $numChapters }) => 100 / $numChapters}%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  padding: 0 ${({ theme }) => theme.grid.margins};
  transition: opacity 0.3s ease;
  opacity: ${({ $navItemState }) => ($navItemState === 0 ? 1 : 0.5)};

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  transform-origin: left;
  transform: scaleX(0);
  z-index: 10;

  &.transitionProgress {
    transition: transform 0.3s ease;
  }
`;

export const MobileBrandNavWrapper = styled(Container)`
  position: relative;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.colors.border.primary};
`;

export const MobileBrandNavInner = styled.nav<{ $brandNavOpen?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: "▼";
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%) rotate(${({ $brandNavOpen }) => ($brandNavOpen ? 180 : 0)}deg);
    transition: transform 0.3s ease;
    font-size: 0.75em;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const MobileBrandNavItem = styled.div<{ $itemOffset: number }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  transform: translateX(${({ $itemOffset }) => ($itemOffset <= 0 ? 0 : $itemOffset * 100)}%);
  transition: transform 0.5s ease;

  & span {
    padding-left: 1rem;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(${({ $itemOffset }) => ($itemOffset >= 1 ? -100 : 0)}%);
    opacity: ${({ $itemOffset }) => {
      switch ($itemOffset) {
        case 0:
          return 1;
        case 1:
          return 0.5;
        default:
          return 0;
      }
    }};
    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }

    transition: opacity 0.5s ${({ $itemOffset }) => ($itemOffset === 0 ? 0.3 : 0)}s ease, transform 0.5s ease;
  }
`;

export const MobileBrandNavMenu = styled(motion.nav)`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.md};

  & > div {
    background-color: ${({ theme }) => theme.colors.nav.subnav};
    padding-bottom: 2.5rem;
  }
`;

export const MobileBrandNavMenuItem = styled(Link)<{ $itemOffset: number }>`
  display: block;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.primary};
  padding: 1.5rem 0;
  font-size: 1.5rem;
  width: 100%;
  opacity: ${({ $itemOffset }) => ($itemOffset === 0 ? 1 : 0.5)};
`;
