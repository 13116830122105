import React, { useMemo } from "react";
import { createContext } from "react";
import { ChapterData } from "~/types/chapter";
import { BrandData } from "~/types/brand";

export interface ChapterContextProviderProps {
  brand: BrandData;
  chapter: ChapterData;
  currentChapterIndex: number;
  hasNextChapter: boolean;
}

export const ChapterContext = createContext<Partial<ChapterContextProviderProps>>({});

export const ChapterContextProvider: React.FC<Partial<ChapterContextProviderProps>> = ({
  brand,
  chapter,
  children,
}) => {
  const currentChapterIndex = useMemo(
    () => brand?.chapters?.findIndex((brandChapter) => brandChapter.slug === chapter?.slug) || 0,
    [chapter?.slug, brand?.chapters],
  );
  const hasNextChapter = useMemo(() => currentChapterIndex < (brand?.chapters?.length || 0) - 1, [
    currentChapterIndex,
    brand?.chapters,
  ]);
  return (
    <ChapterContext.Provider value={{ brand, chapter, currentChapterIndex, hasNextChapter }}>
      {children}
    </ChapterContext.Provider>
  );
};
