// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-chapter-tsx": () => import("./../../../src/templates/chapter.tsx" /* webpackChunkName: "component---src-templates-chapter-tsx" */),
  "component---src-templates-download-tsx": () => import("./../../../src/templates/download.tsx" /* webpackChunkName: "component---src-templates-download-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-localized-404-tsx": () => import("./../../../src/templates/localized404.tsx" /* webpackChunkName: "component---src-templates-localized-404-tsx" */)
}

