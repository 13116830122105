import { useContext } from "react";
import { UIContext, UIContextProps } from "~/context/ui";

export const useUI = (): UIContextProps => {
  const context = useContext(UIContext);

  if (typeof context === "undefined") {
    throw new Error("useUI must be used within a UIProvider");
  }

  return context;
};
